export const environment = {
  production: false,
  apiUrl: 'https://api.asone.global',
  apiKey: 'G37BF2948D54BB2A5CEF4D1BD3F3CA3123',
  asoneUrl: 'https://www.asone.global/csp/asone/REST/api',
  asoneGlobalUrl: 'https://api.asone.global/api/BizzBean/v1/relay/',
};


// https://api.asone.global/api/BizzBean/v1/relay/BBsetup1 : Hits Andre systme
